
<div class="container-privacity-policies">
    <h2>Pol&iacute;ticas de Privacidad</h2>
    <h3>POL&Iacute;TICA DE PRIVACIDAD APLICACI&Oacute;N M&Oacute;VIL</h3>
    
    
    La presente pol&iacute;tica de privacidad establece los términos en que la Asociación de Bancos Privados de Bolivia (ASOBAN) usa y protege la informaci&oacute;n que es proporcionada por sus usuarios al momento de utilizar su aplicación m&oacute;vil.<br>
    La asociaci&oacute;n est&aacute; comprometida con la seguridad de los datos de sus usuarios. Esta política de privacidad puede cambiar con el tiempo o ser actualizada.<br>
    
    <br><b>Información solicitada</b><br>
    Esta aplicaci&oacute;n te solicitar&aacute; el nombre de usuario y contraseña para ingresar a la aplicaci&oacute;n. El número de tel&eacute;fono celular o correo electr&oacute;nico se solicitar&aacute; con fines de verificar tu identidad por lo que ser&aacute;n comparados con los datos del registro.<br>
    No se solicitarán más datos personales.<br>
    
    <br><b>Ubicaci&oacute;n</b><br>
    La aplicaci&oacute;n obtendr&aacute; la ubicaci&oacute;n del usuario con el fin de identificar su ubicaci&oacute;n y proporcionarle la información de la Agencia o ATM más cercano.<br>
    
    <br><b>Acceso a los archivos del tel&eacute;fono</b><br>
    La aplicaci&oacute;n podr&aacute; acceder a los archivos contenidos en el dispositivo m&oacute;vil solamente para la carga y descarga de informaci&oacute;n relacionada a las funcionalidades del aplicativo.<br>
    
</div>